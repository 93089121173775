import styled from "styled-components";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

export const InfoContainer = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#fff")};
  // background: ${({ lightBg }) => (lightBg ? "#707070" : "#010606")};

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const OurTeamInfoContainer = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#fff")};
  // background: ${({ lightBg }) => (lightBg ? "#707070" : "#010606")};

  @media screen and (max-width: 768px) {
    padding: 0 0 120px 0;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  z-index: 1;
  height: 860px;
  // height: 680px;
  width: 100%;
  // max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  // padding: 0 24px;
  padding: 4rem 0rem;
  justify-content: center;
`;

export const InfoRow = styled.div`
  display: grid;
  // grid-auto-columns: minmax(auto, 1fr);
  grid-template-columns: 1fr 1fr;
  padding: 3rem calc((100vw - 1300px) / 2);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;

export const TopLine = styled.p`
  color: #339933;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Headings = styled.div`
  display: flex;
`;

export const HeadingGreen = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #369e3a;
  padding-right: 15px;

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const HeadingGreen2 = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #369e3a;
  padding-right: 15px;
  padding-left: 15px;

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const HeadingGrey = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #6e6f6f;

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const SubTitle = styled.p`
  margin-width: 440px;
  margin-bottom: 25px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
`;

export const BtnWrap = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  margin-width: 555px;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;

  // border-radius: ${({ profilePic }) => (profilePic ? "125px" : "0")};
  border: ${({ profilePic }) => (profilePic ? "5px solid #339933" : "0")};
`;

export const DetailLink = styled(Link)`
  color: #0645ad;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

export const CallButton = styled.a`
  color: #fff;
`;
