import styled from "styled-components";

export const MapContainer = styled.div``;

export const MapHeading = styled.h2`
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;

  @media screen and (min-width: 799px) {
    font-size: 1.3rem;
    font-weight: 400;
  }
`;

export const MapBanner = styled.div`
  width: 100%;
  height: 60vh;

  @media screen and (min-width: 799px) {
    height: 80vh;
  }
`;

export const LocationPointer = styled.div`
  display: flex;
  align-items: center;
  width: 180px;
  color: var(--main-blue);

  @media screen and (min-width: 799px) {
    width: 15vw;
  }
`;

export const LocationIcon = styled.div`
  font-size: 4rem;

  @media screen and (min-width: 799px) {
    font-size: 10vw;
  }
`;

export const LocationText = styled.p`
  font-size: 1.3em;
`;
