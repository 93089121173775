import mission_image from "../../images/mission_image.svg";
import values_image from "../../images/values_image.svg";
import what_we_do_image from "../../images/what_we_do_image.svg";
import who_we_are_image from "../../images/who_we_are_image.svg";

export const aboutUsObjOne = {
  id: "mission",
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Setia Real Estate",
  headline: "Our Mission",
  headline1: "Our",
  headline2: "Mission",
  description:
    "Our mission is to “Provide a happy place that you call your dream home”.",
  buttonLabel: "Let's Get Moving",
  //   buttonLink: "/contactusappraisal",
  imgStart: true,
  img: mission_image,
  alt: "Our Mission",
  dark: true,
  primary: false,
  darkText: true,
};

export const aboutUsObjTwo = {
  id: "values",
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  topLine: "Setia Real Estate",
  headline: "Our Values",
  headline1: "Our",
  headline2: "Values",
  description:
    "Our values are to be ethical, be transparent with our customers and be accessible to our customers.",
  buttonLabel: "Let's Get Moving",
  //   buttonLink: "/contactusappraisal",
  imgStart: false,
  img: values_image,
  alt: "Our Values",
  dark: true,
  primary: false,
  darkText: true,
};

export const aboutUsObjThree = {
  id: "whoweare",
  lightBg: false,
  lightText: false,
  lightTextDesc: true,
  topLine: "Setia Real Estate",
  headline: "Who We Are",
  headline1: "Who",
  headline2: "We",
  headline3: "Are",
  description:
    "Setia is a renowned real estate organization with world-class customer services and top results.",
  buttonLabel: "Let's Get Moving",
  //   buttonLink: "/contactusappraisal",
  imgStart: true,
  img: who_we_are_image,
  alt: "Who We Are",
  dark: true,
  primary: false,
  darkText: true,
};

export const aboutUsObjFour = {
  id: "whatdowedo",
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  topLine: "Setia Real Estate",
  headline: "What Do We Do",
  headline1: "What",
  headline2: "Do",
  headline3: "We",
  headline4: "Do",
  description:
    "Help you find a perfect home to buy or rent also we help you sell your home in good hands.",
  buttonLabel: "Let's Get Moving",
  //   buttonLink: "/contactusappraisal",
  imgStart: false,
  img: what_we_do_image,
  alt: "Our Values",
  dark: true,
  primary: false,
  darkText: true,
};
