import React from "react";
import about_us_image from "../../images/about_us_background.jpeg";
import Banner from "../banner";
import { AboutUsContainer, AboutUsDetails, AboutUsP } from "./AboutUsElements";
import InfoSection from "../infosection/index_aboutus";
import {
  aboutUsObjOne,
  aboutUsObjTwo,
  aboutUsObjThree,
  aboutUsObjFour,
} from "../aboutus/Data";

class AboutUs extends React.Component {
  render() {
    return (
      <AboutUsContainer>
        <Banner
          banner_img={about_us_image}
          banner_alt={"Banner Image"}
          banner_heading={"About Us"}
        />

        <AboutUsDetails>
          <AboutUsP>
            Setia Real Estate is one of Sydney’s leading and fastest growing
            real estate agencies.
            {"\n"}
            {"\n"}
            Principal Director, Rajesh Setia, established Setia Real Estate to
            embody his long standing family values of “good will” and
            outstanding service for all.
            {"\n"}
            {"\n"}
            Setia Real Estate is not just another agency to sell your “house”,
            but is a partner which understands the value of your “home”, and
            will personally strive to provide you with the best possible
            outcome.
            {"\n"}
            {"\n"}
            Setia Real Estate is a two time award winning "Agency of the Year"
            along with Rajesh Setia receiving "Agent of the Year" five years in
            a row, with numerous record breaking sales across Western Sydney.
            His team, Team Setia, are all hand-picked processionals, and are
            carefully trained to provide everyone - prospective buyers and
            sellers - the "Setia experience".
            {"\n"}
            {"\n"}
            Whether you are buying, selling or renting a home, our professional
            staff at Setia Real Estate will handle all your queries with clarity
            and confidence.
            {"\n"}
            {"\n"}
            From the first signing, to the final selling, you will immensely be
            satisfied with Setia Real Estate, allowing you to continue your
            personal journey to achieve your dreams.
            {"\n"}
            {"\n"}
            Setia Real Estate is another place you can call home.
            {"\n"}
            {"\n"}
            Team Setia looks forward to meeting you soon.
          </AboutUsP>
        </AboutUsDetails>
        <InfoSection {...aboutUsObjOne} />
        <InfoSection {...aboutUsObjTwo} />
        <InfoSection {...aboutUsObjThree} />
        <InfoSection {...aboutUsObjFour} />
      </AboutUsContainer>
    );
  }
}

export default AboutUs;
