import styled from "styled-components";

export const PropertyInfoProcessingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 460px;
`;

export const PropertyInfoContainer = styled.div`
  width: 100%;
  padding-right: 100px;
  padding-left: 100px;
  margin-right: auto;
  margin-left: auto;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "col1 col2";
  display: flex;
  max-width: 1450px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-areas: "col1" "col2";
    padding-right: 15px;
    padding-left: 15px;
    display: block;
  }
`;

export const PropertyContainer = styled.div`
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  grid-area: col1;

  @media screen and (max-width: 1024px) {
    flex: 0 0 99.666667%;
    max-width: 99.666667%;
  }
`;

export const PropertyContactContainer = styled.div`
  flex: 0 0 33.666667%;
  max-width: 33.666667%;
  grid-area: col2;
  padding-left: 40px;

  @media screen and (max-width: 1024px) {
    flex: 0 0 99.666667%;
    max-width: 99.666667%;
    padding-left: 0;
  }
`;
export const CarouselWrapper = styled.div`
  overflow: hidden;
  position: relative;
  background: white;
  background-color: #fff;
`;

export const DetailWrapper = styled.div`
  padding: 70px 30px;
  border: 1px solid #e1e1e1;
  border-top: none;
`;

export const AddressDetail = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "col1 col2";

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
    grid-template-areas: "col1" "col2";
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  grid-area: col1;
  text-align: left;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  grid-area: col2;
  text-align: right;

  @media screen and (max-width: 760px) {
    margin-top: 15px;
    text-align: left;
  }
`;

export const AddressDetailHeading = styled.h2`
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 5px;
  color: #6e6f6f;
`;

export const PropertyDetailSuburbIcon = styled.i`
  color: #369e3a;
  padding-right: 10px;
`;

export const AddressDetailSuburb = styled.p`
  font-size: 18px;
  color: #6e6f6f;
`;

export const PriceDetail = styled.div`
  display: inline-block;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  padding: 9px;
  background: #369e3a;
  color: #fff;
  min-width: 200px;
  border-radius: 3px;
  margin-bottom: 70px;
  font-variant: all-petite-caps;
`;

export const PropertyDetailHeading = styled.h3`
  display: block;
  font-size: 23px;
  font-weight: 400;
  color: #6e6f6f;
  padding-bottom: 20px;
  margin-bottom: 25px;
  border-bottom: 1px solid #ebebeb;
`;

export const PropertyDetail = styled.div`
  margin-bottom: 40px;
  font-size: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "col1 col2 col3";

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
    grid-template-areas: "col1" "col2" "col3";
  }
`;

export const PropertyColumn1 = styled.div`
  margin-bottom: 15px;
  grid-area: col1;
  color: #6e6f6f;

  @media screen and (max-width: 760px) {
    margin-bottom: 55px;
  }
`;

export const PropertyColumn2 = styled.div`
  margin-bottom: 15px;
  grid-area: col2;
  color: #6e6f6f;

  @media screen and (max-width: 760px) {
    margin-bottom: 55px;
  }
`;

export const PropertyColumn3 = styled.div`
  margin-bottom: 15px;
  grid-area: col3;
  color: #6e6f6f;
`;

export const PropertyDescription = styled.p`
  font-size: 15.7px;
  color: #6e6f6f;
  line-height: 1.6;
  white-space: pre-line;
`;

export const PropertyContact = styled.div`
  padding: 29px;
  border: 1px solid #ebebeb;
  border-radius: 1px;
  margin-bottom: 44px;
  overflow: hidden;
  position: relative;
  margin-top: 40px;
`;

export const PropertyContactImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  float: left;
  margin-right: 25px;
  border: 2px solid #369e3a;
`;

export const PropertyContactDetails = styled.div``;

export const PropertyContactInfoDetails = styled.div`
  display: block;
  clear: both;
  padding-top: 40px;
  position: relative;
`;

export const PropertyContactName = styled.h5`
  margin-top: 20px;
  font-weight: 600;
  color: #369e3a;
  text-transform: uppercase;
`;

export const PropertyContactLastName = styled.span`
  color: #6e6f6f;
`;

export const PropertyContactCompany = styled.p`
  font-size: 8.9px;
  font-weight: 400;
  color: #707070;
  line-height: 2.2;
  color: #6e6f6f;
  text-transform: uppercase;
`;

export const PropertyContactEmail = styled.p`
  font-size: 14.9px;
  color: #707070;
  line-height: 2.2;
  color: #6e6f6f;
`;

export const PropertyContactPhone = styled.p`
  font-size: 14.9px;
  color: #707070;
  line-height: 2.2;
  color: #6e6f6f;
`;

export const PropertyContactForm = styled.form`
  padding: 29px;
  border: 1px solid #ebebeb;
  border-radius: 1px;
  margin-bottom: 80px;
`;

export const FormH1 = styled.h5`
  font-weight: 400;
  margin-bottom: 30px;
  color: #369e3a;
`;

export const TextBoxName = styled.input`
  width: 100%;
  border: 1px solid #ebebeb;
  margin-bottom: 17px;
  height: 36px;
  padding: 0 20px;
`;

export const TextBoxEmail = styled.input`
  width: 100%;
  border: 1px solid #ebebeb;
  margin-bottom: 17px;
  height: 36px;
  padding: 0 20px;
`;

export const TextBoxPhone = styled.input`
  width: 100%;
  border: 1px solid #ebebeb;
  margin-bottom: 17px;
  height: 36px;
  padding: 0 20px;
`;

export const TextArea = styled.textarea`
  height: 100px;
  padding: 10px 20px;
  width: 100%;
  border: 1px solid #ebebeb;
  margin-bottom: 17px;
`;

export const FormButton = styled.button`
  background: #369e3a;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border: none;
  min-width: 80px;
  height: 30px;
  border-radius: 1px;
  cursor: pointer;
`;

export const ProcessingImgWrapper = styled.img`
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
`;
