import React from "react";
import Banner from "../banner";
import InfoSection from "../infosection/index_ourteam";
import { OurTeamContainer } from "./OurTeamElements";
import {
  rajeshsetia,
  hemasetia,
  mannysingh,
  khushwantgill,
  pankajsharma,
  michaeltorrisi,
  shivangipawar,
} from "../ourteam/Data";

class OurTeam extends React.Component {
  render() {
    return (
      <OurTeamContainer>
        <Banner
          banner_img={
            "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/assets/static/about_us_background.jpeg"
          }
          banner_alt={"Banner Image"}
          banner_heading={"Our Team"}
        />
        <InfoSection {...rajeshsetia} />
        <InfoSection {...hemasetia} />
        <InfoSection {...mannysingh} />
        <InfoSection {...khushwantgill} />
        <InfoSection {...pankajsharma} />
        <InfoSection {...michaeltorrisi} />
        <InfoSection {...shivangipawar} />
      </OurTeamContainer>
    );
  }
}

export default OurTeam;
