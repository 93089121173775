import React from "react";
import { useParams } from "react-router-dom";
import {
  rajeshsetia,
  hemasetia,
  mannysingh,
  khushwantgill,
  pankajsharma,
  michaeltorrisi,
  shivangipawar,
} from "../ourteam/Data";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  ImgWrap,
  Img,
  TextWrapper,
  TopLine,
  Heading,
  SubHeading,
  SubTitle,
} from "./OurTeamElements";

const TeamProfile = () => {
  const agentName = useParams().agentname;

  let agentProfile = {};
  if (agentName === "rajeshsetia") {
    agentProfile = rajeshsetia;
  } else if (agentName === "hemasetia") {
    agentProfile = hemasetia;
  } else if (agentName === "mannysingh") {
    agentProfile = mannysingh;
  } else if (agentName === "khushwantgill") {
    agentProfile = khushwantgill;
  } else if (agentName === "pankajsharma") {
    agentProfile = pankajsharma;
  } else if (agentName === "michaeltorrisi") {
    agentProfile = michaeltorrisi;
  } else {
    agentProfile = shivangipawar;
  }

  return (
    <>
      {/* <Banner
        banner_img={about_us_image}
        banner_alt={"Banner Image"}
        banner_heading={agentProfile.headline}
      /> */}
      <InfoContainer>
        <InfoWrapper>
          <InfoRow>
            <Column1>
              <ImgWrap>
                <Img src={agentProfile.img} alt={agentProfile.alt} />
              </ImgWrap>
            </Column1>
            <Column2>
              <TextWrapper>
                <TopLine>{agentProfile.topLine}</TopLine>
                <Heading>{agentProfile.headline}</Heading>
                <SubHeading>
                  <i class="fa fa-envelope" aria-hidden="true"></i>{" "}
                  {agentProfile.emailId}
                </SubHeading>
                <SubHeading>
                  <i class="fa fa-phone" aria-hidden="true"></i>{" "}
                  {agentProfile.contactNumber}
                </SubHeading>
                <SubTitle>{agentProfile.completeDescription}</SubTitle>
              </TextWrapper>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default TeamProfile;
