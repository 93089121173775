import styled from "styled-components";

export const PopUpContainer = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 50px;
  left: 0;
`;

export const PopUpBox = styled.div`
  position: relative;
  width: 38%;
  margin: 0 auto;
  // height: auto;
  max-height: 65vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  border: 1px solid #999;
  overflow: hidden;
  color: #339933;
  white-space: pre-wrap;

  @media screen and (max-width: 768px) {
    width: 80%;
  }
`;

export const PopUpClose = styled.span`
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(31% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #000;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
  margin-top: 60px;

  @media screen and (max-width: 768px) {
    right: calc(10% - 30px);
  }
`;
