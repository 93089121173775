import React, { useState } from "react";
import Sidebar from "../sidebar/onlyHomeIndex";
import Navbar from "../navbar/onlyHomeIndex";
import Footer from "../footer";
import ScrollToTop from "../ScrollToTop";
import ContactUsForAppraisal from "../contactus/contactUsForAppraisal";

const ContactUsAppraisal = () => {
  const [isOpen, setisOpen] = useState(false);

  const toggle = () => {
    setisOpen(!isOpen);
  };

  return (
    <>
      <ScrollToTop />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <ContactUsForAppraisal />
      <Footer />
    </>
  );
};

export default ContactUsAppraisal;
