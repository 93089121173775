import React from "react";

import { PopUpContainer, PopUpBox, PopUpClose } from "./PopupElements";

const Popup = (props) => {
  return (
    <PopUpContainer>
      <PopUpBox>
        <PopUpClose onClick={props.handleClose}>x</PopUpClose>
        {props.content}
      </PopUpBox>
    </PopUpContainer>
  );
};

export default Popup;
