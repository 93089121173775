import React from "react";
import {} from "./ContactUsElements";
import MapSection from "../map";
import message_image from "../../images/message_image.svg";
import emailjs from "@emailjs/browser";

import {
  ContactUsContainer,
  ContactInfoContainer,
  ContactInfoParagraph,
  ContactInfoParagraphIcon,
  ContactInfoParagraphLast,
  ContactUsFormContainer,
  ContactUsFormWrapper,
  ContactUsFormRow,
  ContactUsColumn1,
  ContactUsFormImageWrap,
  Img,
  ContactUsColumn2,
  Form,
  FormH1,
  TextBoxName,
  TextBoxEmail,
  TextBoxPhone,
  TextArea,
  FormButton,
} from "./ContactUsElements";

const location = {
  address: "Shop 3B/60 Rosenthal St, Doonside, NSW, 2767",
  lat: -33.7729433,
  lng: 150.8767012,
};

const resetForm = () => {
  document.getElementById("emailForm").reset();
};

const sendEmail = (name, email, phone, message) => {
  var templateParams = {
    agent_email: "office@setiarealestate.com.au",
    agent_name: "Team Setia",
    from_name: name,
    from_email: email,
    phone: phone,
    message: message,
  };

  emailjs
    .send(
      "service_g0xo4se",
      "template_u4x416i",
      templateParams,
      "fCYrp4AAfFBwtwTHX"
    )
    .then(
      function (response) {
        console.log("SUCCESS!", response.status, response.text);
        emailjs
          .send(
            "service_g0xo4se",
            "template_er4ndy4",
            templateParams,
            "fCYrp4AAfFBwtwTHX"
          )
          .then(
            function (response) {
              console.log("SUCCESS!", response.status, response.text);
            },
            function (error) {
              console.log("FAILED...", error);
            }
          );
        alert("Your message has been successfully sent!");
        resetForm();
      },
      function (error) {
        console.log("FAILED...", error);
        alert(
          "Sorry, your message could not be delivered! Please try again later"
        );
        resetForm();
      }
    );
};

class ContactUsForAppraisal extends React.Component {
  render() {
    function sendButtonClicked(e) {
      e.preventDefault();
      const { name, email, phone, message } = e.target.elements;

      sendEmail(name.value, email.value, phone.value, message.value);
    }

    return (
      <ContactUsContainer>
        <MapSection location={location} zoomLevel={14} />
        <ContactInfoContainer>
          <ContactInfoParagraph>
            <ContactInfoParagraphIcon>
              <i class="fa fa-map-marker"></i>
            </ContactInfoParagraphIcon>
            3B/60 Rosenthal St, Doonside, NSW, 2767
          </ContactInfoParagraph>
          <ContactInfoParagraph>
            <ContactInfoParagraphIcon>
              <i class="fa fa-envelope"></i>
            </ContactInfoParagraphIcon>
            office@setiarealestate.com.au
          </ContactInfoParagraph>
          <ContactInfoParagraphLast>
            <ContactInfoParagraphIcon>
              <i class="fa fa-phone"></i>
            </ContactInfoParagraphIcon>
            02 7202 9411
          </ContactInfoParagraphLast>
        </ContactInfoContainer>
        <ContactUsFormContainer>
          <ContactUsFormWrapper>
            <ContactUsFormRow>
              <ContactUsColumn1>
                <ContactUsFormImageWrap>
                  <Img src={message_image} alt="contact-us-image" />
                </ContactUsFormImageWrap>
              </ContactUsColumn1>
              <ContactUsColumn2>
                <Form onSubmit={sendButtonClicked}>
                  <FormH1>Send us a message</FormH1>
                  <TextBoxName
                    name="name"
                    type="text"
                    placeholder="Your name"
                  ></TextBoxName>
                  <TextBoxEmail
                    type="text"
                    name="email"
                    placeholder="Your email"
                  ></TextBoxEmail>
                  <TextBoxPhone
                    type="text"
                    name="phone"
                    placeholder="Your phone number"
                  ></TextBoxPhone>
                  <TextArea
                    name="message"
                    placeholder="Your question"
                  ></TextArea>
                  <FormButton type="submit">SEND</FormButton>
                </Form>
              </ContactUsColumn2>
            </ContactUsFormRow>
          </ContactUsFormWrapper>
        </ContactUsFormContainer>
      </ContactUsContainer>
    );
  }
}

export default ContactUsForAppraisal;
