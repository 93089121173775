import React from "react";
import { withRouter } from "react-router";
import axios from "axios";
import NumberFormat from "react-number-format";

import {
  PropertiesContainer,
  PropertiesWrapper,
  PropertiesH1,
  PropertiesIcon,
  PropertyUnderOfferImg,
  PropertiesCard,
  PropertiesImgWrapper,
  PropertiesCardStatus,
  PropertiesH3,
  PropertiesP,
  PropertiesInfo,
  PropertiesRoomInfo,
  PropertiesRoomInfoLeft,
  PropertiesRoomInfoRight,
  PropertiesRoomInfoText,
  PropertiesRoomInfoIcon,
  PropertyRoomInfoWrapper,
  PropertiesSmallIcon,
  PropertiesPrice,
  PropertiesRoomInfoLastRight,
  ProcessingImgWrapper,
  InfoSectionBtnWrap,
  InfoSelectionButtonGreen,
  InfoSelectionButton,
  MorePropertiesButtonWrapper,
  MorePropertiesLoaderWrapper,
  MorePropertiesButton,
  ArrowForward,
  ArrowRight,
} from "./PropertiesElements";
import Banner from "../banner";
// import { Button } from "../ButtonElements";

// const API = "http://localhost:8080/";
const API = "https://proxysetiarealestate.com/";
const loading_properties =
  "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/animations/Processing-Icon.gif";
const image_unavailable =
  "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/assets/static/Image_unavailable.jpeg";
const image_under_offer =
  "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/assets/static/under_offer_logo.png";

class Properties extends React.Component {
  state = {
    loading: true,
    loading_more: false,
    properties: [],
    error: null,
    endpoint: "getProperties",
    infoSelection: null,
    toBuyClick: false,
    toRentClick: false,
    soldClick: false,
    offTheMarketClick: false,
    heading1: "Properties Available To Buy",
    status: "listing,conditional",
    sort: "inserted",
    sortOrder: "desc",
    published: "true",
    page: 1,
    pagesize: 9,
    hover: false,
    maximumpages: 0,
    buttonclicked: this.props.location.data
      ? this.props.location.data.buttonclicked
      : "toBuyClick",
  };

  changeInfoSelection = (e) => {
    this.setState({ isLoading: true });
    if (e.target.innerHTML === "Rent") {
      this.setState(
        {
          toRentClick: true,
          soldClick: false,
          offTheMarketClick: false,
          toBuyClick: false,
          status: "listing",
          published: "true",
          loading: true,
          endpoint: "getPropertiesForLease",
          sort: "modified",
          heading1: "Properties Available To Rent",
          buttonclicked: "toRentClick",
        },
        this.makeAxiosRequest
      );
    } else if (e.target.innerHTML === "Sold") {
      this.setState(
        {
          toRentClick: false,
          soldClick: true,
          offTheMarketClick: false,
          toBuyClick: false,
          status: "unconditional",
          loading: true,
          heading1: "Properties Recently Sold",
          endpoint: "getSoldProperties",
          sort: "unconditional",
          buttonclicked: "soldClick",
        },
        this.makeAxiosRequest
      );
    } else if (e.target.innerHTML === "Off Market") {
      this.setState(
        {
          toRentClick: false,
          soldClick: false,
          offTheMarketClick: true,
          toBuyClick: false,
          status: "listing",
          published: "false",
          loading: true,
          endpoint: "getPropertiesForSale",
          sort: "inserted",
          heading1: "Properties Available Off The Market",
          buttonclicked: "offTheMarketClick",
        },
        this.makeAxiosRequest
      );
    } else if (e.target.innerHTML === "Buy") {
      this.setState(
        {
          toRentClick: false,
          soldClick: false,
          offTheMarketClick: false,
          toBuyClick: true,
          status: "listing,conditional",
          published: "true",
          loading: true,
          endpoint: "getPropertiesForSale",
          sort: "inserted",
          heading1: "Properties Available To Buy",
          buttonclicked: "toBuyClick",
        },
        this.makeAxiosRequest
      );
    }
  };

  LoadMore = (e) => {
    e.preventDefault();

    if (this.state.maximumpages > this.state.page) {
      this.setState({
        page: this.state.page + 1,
        loading_more: true,
      });
      this.makeFollowingAxiosRequest();
    }
  };

  makeFollowingAxiosRequest = async (dispatch) => {
    try {
      const response = await axios.get(API + this.state.endpoint, {
        params: {
          status: this.state.status,
          sort: this.state.sort,
          sortOrder: this.state.sortOrder,
          published: this.state.published,
          page: this.state.page + 1,
          pagesize: this.state.pagesize,
        },
      });
      for (let i = 0; i < response.data.items.length; i++) {
        this.setState({
          properties: [...this.state.properties, response.data.items[i]],
          loading_more: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        error: error,
      });
    }
  };

  makeAxiosRequest = async (dispatch) => {
    try {
      const response = await axios.get(API + this.state.endpoint, {
        params: {
          status: this.state.status,
          sort: this.state.sort,
          sortOrder: this.state.sortOrder,
          published: this.state.published,
          page: this.state.page,
          pagesize: this.state.pagesize,
        },
      });

      this.setState({
        properties: response.data.items,
        loading: false,
        maximumpages: response.data.totalPages,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        error: error,
        loading: false,
      });
    }
  };

  async componentDidMount() {
    this.setState({
      isLoading: true,
      buttonclicked: this.props.location.data
        ? this.props.location.data.buttonclicked
        : "",
    });
    if (this.state.buttonclicked === "offTheMarketClick") {
      this.setState(
        {
          toRentClick: false,
          soldClick: false,
          offTheMarketClick: true,
          toBuyClick: false,
          status: "listing",
          published: "false",
          loading: true,
          endpoint: "getPropertiesForSale",
          sort: "inserted",
          heading1: "Properties Available Off The Market",
        },
        this.makeAxiosRequest
      );
    } else if (this.state.buttonclicked === "soldClick") {
      this.setState(
        {
          toRentClick: false,
          soldClick: true,
          offTheMarketClick: false,
          toBuyClick: false,
          status: "unconditional",
          loading: true,
          heading1: "Properties Recently Sold",
          endpoint: "getSoldProperties",
          sort: "unconditional",
        },
        this.makeAxiosRequest
      );
    } else if (this.state.buttonclicked === "toBuyClick") {
      this.setState(
        {
          toRentClick: false,
          soldClick: false,
          offTheMarketClick: false,
          toBuyClick: true,
          status: "listing,conditional",
          published: "true",
          loading: true,
          endpoint: "getPropertiesForSale",
          sort: "inserted",
          heading1: "Properties Available To Buy",
        },
        this.makeAxiosRequest
      );
    } else if (this.state.buttonclicked === "toRentClick") {
      this.setState(
        {
          toRentClick: true,
          soldClick: false,
          offTheMarketClick: false,
          toBuyClick: false,
          status: "listing",
          published: "true",
          loading: true,
          endpoint: "getPropertiesForLease",
          sort: "modified",
          heading1: "Properties Available To Rent",
        },
        this.makeAxiosRequest
      );
    }

    // try {
    //   const response = await axios.get(API + this.state.endpoint, {
    //     params: {
    //       status: this.state.status,
    //       sort: this.state.sort,
    //       sortOrder: this.state.sortOrder,
    //       published: this.state.published,
    //       page: this.state.page,
    //       pagesize: this.state.pagesize,
    //     },
    //   });
    //   this.setState({
    //     properties: response.data.items,
    //     loading: false,
    //     maximumpages: response.data.totalPages,
    //   });
    // } catch (error) {
    //   this.setState({
    //     error: error,
    //     loading: false,
    //   });
    // }
  }

  render() {
    const BuyPropertiesButton =
      this.state.toBuyClick || this.state.buttonclicked === "toBuyClick"
        ? InfoSelectionButtonGreen
        : InfoSelectionButton;
    const RentPropertiesButton =
      this.state.toRentClick || this.state.buttonclicked === "toRentClick"
        ? InfoSelectionButtonGreen
        : InfoSelectionButton;
    const SoldPropertiesButton =
      this.state.soldClick || this.state.buttonclicked === "soldClick"
        ? InfoSelectionButtonGreen
        : InfoSelectionButton;
    const OffTheMarketPropertiesButton =
      this.state.offTheMarketClick ||
      this.state.buttonclicked === "offTheMarketClick"
        ? InfoSelectionButtonGreen
        : InfoSelectionButton;

    return (
      <>
        <Banner
          banner_img={
            "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/assets/static/about_us_background.jpeg"
          }
          banner_alt={"Banner Image"}
          banner_heading={"Properties"}
        />
        <PropertiesContainer>
          <InfoSectionBtnWrap>
            <BuyPropertiesButton onClick={this.changeInfoSelection}>
              Buy
            </BuyPropertiesButton>
            <RentPropertiesButton onClick={this.changeInfoSelection}>
              Rent
            </RentPropertiesButton>
            <SoldPropertiesButton onClick={this.changeInfoSelection}>
              Sold
            </SoldPropertiesButton>
            <OffTheMarketPropertiesButton onClick={this.changeInfoSelection}>
              Off Market
            </OffTheMarketPropertiesButton>
          </InfoSectionBtnWrap>
          <PropertiesH1>{this.state.heading1}</PropertiesH1>
          {this.state.loading ? (
            <ProcessingImgWrapper
              src={loading_properties}
              alt="Loading Image"
            ></ProcessingImgWrapper>
          ) : (
            <PropertiesWrapper>
              {this.state.properties.map((property) => (
                <PropertiesCard
                  to={
                    property.status === "management"
                      ? `/property/${property.id}/${"forLease"}`
                      : `/property/${property.id}/${"forSale"}`
                  }
                >
                  <PropertiesImgWrapper>
                    <PropertiesCardStatus>
                      {property.status === "listing"
                        ? "FOR SALE"
                        : property.status === "conditional"
                        ? "UNDER OFFER"
                        : property.status === "management"
                        ? "FOR LEASE"
                        : property.status === "settled" ||
                          property.status === "unconditional"
                        ? "SOLD"
                        : "OFF THE MARKET"}
                    </PropertiesCardStatus>
                    <PropertiesIcon
                      src={
                        property.photos[0]
                          ? property.photos[0].url
                          : image_unavailable
                      }
                      alt="Property Image"
                    ></PropertiesIcon>
                    {property.status === "conditional" ? (
                      <PropertyUnderOfferImg
                        src={image_under_offer}
                        alt="Under Offer Property Image"
                      ></PropertyUnderOfferImg>
                    ) : (
                      ""
                    )}
                  </PropertiesImgWrapper>
                  <PropertiesInfo>
                    <PropertiesH3>
                      {property.displayAddress.split(",")[0]}
                    </PropertiesH3>
                    <PropertiesP>
                      <PropertiesSmallIcon>
                        <i class="fa fa-map-marker"></i>
                      </PropertiesSmallIcon>
                      {property.address.suburb.name},{" "}
                      {property.address.suburb.postcode}
                    </PropertiesP>
                    {/* <PropertiesInfo> */}
                    <PropertyRoomInfoWrapper>
                      <PropertiesRoomInfo>
                        <PropertiesRoomInfoLeft>
                          <PropertiesRoomInfoText>
                            <PropertiesRoomInfoIcon>
                              <i class="fa fa-bed"></i>
                            </PropertiesRoomInfoIcon>
                            {property.bed} Bedrooms
                          </PropertiesRoomInfoText>
                          <PropertiesRoomInfoText>
                            <PropertiesRoomInfoIcon>
                              <i class="fa fa-car"></i>
                            </PropertiesRoomInfoIcon>
                            {property.garages} Garages
                          </PropertiesRoomInfoText>
                        </PropertiesRoomInfoLeft>
                        <PropertiesRoomInfoRight>
                          <PropertiesRoomInfoText>
                            <PropertiesRoomInfoIcon>
                              <i class="fa fa-bath"></i>
                            </PropertiesRoomInfoIcon>
                            {property.bath} Bathrooms
                          </PropertiesRoomInfoText>
                          <PropertiesRoomInfoText>
                            <PropertiesRoomInfoIcon>
                              <i class="fa fa-th-large"></i>
                            </PropertiesRoomInfoIcon>
                            {property.floorArea.value} Square Metre
                          </PropertiesRoomInfoText>
                        </PropertiesRoomInfoRight>
                      </PropertiesRoomInfo>
                      <PropertiesRoomInfo>
                        <PropertiesRoomInfoLeft>
                          <PropertiesRoomInfoText>
                            <PropertiesRoomInfoIcon>
                              <i class="fa fa-user"></i>
                            </PropertiesRoomInfoIcon>
                            {property.contactStaff[0].firstName}{" "}
                            {property.contactStaff[0].lastName}
                          </PropertiesRoomInfoText>
                        </PropertiesRoomInfoLeft>
                        {property.contactStaff[1] ? (
                          <PropertiesRoomInfoLastRight>
                            <PropertiesRoomInfoText>
                              <PropertiesRoomInfoIcon>
                                <i class="fa fa-user"></i>
                              </PropertiesRoomInfoIcon>
                              {property.contactStaff[1].firstName}{" "}
                              {property.contactStaff[1].lastName}
                            </PropertiesRoomInfoText>
                          </PropertiesRoomInfoLastRight>
                        ) : (
                          ""
                        )}
                      </PropertiesRoomInfo>
                      <PropertiesPrice href="#">
                        {property.status === "unconditional" ||
                        property.status === "settled" ? (
                          <NumberFormat
                            value={property.saleDetails.salePrice}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$ "}
                          />
                        ) : property.displayPrice === "" ? (
                          property.searchPrice !== 0 ? (
                            <NumberFormat
                              value={property.searchPrice}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$ "}
                            />
                          ) : (
                            "Contact Agent"
                          )
                        ) : (
                          property.displayPrice
                        )}
                      </PropertiesPrice>
                    </PropertyRoomInfoWrapper>
                  </PropertiesInfo>
                </PropertiesCard>
              ))}
            </PropertiesWrapper>
          )}
          {this.state.loading_more ? (
            <MorePropertiesLoaderWrapper>
              <ProcessingImgWrapper
                src={loading_properties}
                alt="Loading Image"
              ></ProcessingImgWrapper>
            </MorePropertiesLoaderWrapper>
          ) : this.state.maximumpages > this.state.page ? (
            <MorePropertiesButtonWrapper>
              <MorePropertiesButton
                onMouseEnter={() => this.setState({ hover: true })}
                onMouseLeave={() => this.setState({ hover: false })}
                onClick={this.LoadMore}
              >
                Load More {this.state.hover ? <ArrowForward /> : <ArrowRight />}
              </MorePropertiesButton>
            </MorePropertiesButtonWrapper>
          ) : (
            ""
          )}
        </PropertiesContainer>
      </>
    );
  }
}

export default withRouter(Properties);
