import styled from "styled-components";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

export const Card = styled.div`
  padding: 4rem;
  background: #fff;

  @media only screen and (max-width: 480px) {
    padding: 4rem 0 4rem 0;
    // margin-top: 4rem;
  }
`;

export const CardTitle = styled.h1`
  text-align: center;
  color: #369e3a;
  text-transform: uppercase;
`;

export const InfoSectionBtnWrap = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  max-width: 800px;
  margin: 50px auto;
  justify-content: center;

  @media only screen and (max-width: 760px) {
    flex-flow: column;
    display: grid;
    // grid-template-columns: 1fr 1fr;
    max-width: 400px;
  }
`;

export const InfoSelectionButton = styled.button`
  border-radius: 50px;
  min-width: 200px;
  background: #6e6f6f;
  white-space: nowrap;
  padding: 10px 22px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: 0.2s ease-in-out;
    background: #369e3a;
    color: #010606;
  }

  @media only screen and (max-width: 760px) {
    margin-bottom: 0.5rem;
  }
`;

export const InfoSelectionButtonGreen = styled.button`
  border-radius: 50px;
  min-width: 200px;
  // background: ${({ buttonColor }) => (buttonColor ? "#339933" : "#000")};
  background: #369e3a;
  white-space: nowrap;
  padding: 10px 22px;
  color: #000;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  @media only screen and (max-width: 760px) {
    margin-bottom: 0.5rem;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
`;

export const CardWrapper = styled.div`
  position: relative;
  margin: 50px 0 45px;
  width: 100%;
`;

export const CardItems = styled.ul`
  margin-bottom: 24px;

  @media only screen and (min-width: 1024px) {
    display: flex;
  }
`;

export const CardItem = styled.li`
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
  border: 3px solid #369e3a;
  max-width: 550px;

  @media only screen and (max-width: 1024px) {
    margin-bottom: 2rem;
  }
`;

export const CardItemLink = styled(Link)`
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 6px;
  overflow: hidden;
  text-decoration: none;
`;

export const ProcessingImgWrapper = styled.img`
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
`;

export const CardItemImgWrapper = styled.figure`
  position: relative;
  width: 100%;
  // padding-top: 67%;
  overflow: hidden;
`;

export const CardItemImg = styled.img`
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;

  &:hover {
    transform: scale(1.1);
  }
`;

export const CardItemUnderOfferImg = styled.img`
  position: absolute;
  top: 20%;
  left: 25%;
  display: block;
  width: 50%;
  height: 70%;
  transition: all 0.2s linear;
`;

export const CardStatus = styled.div`
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  background: #369e3a;
  padding: 7px 14px;
  display: inline-block;
  border-radius: 1px;
  position: absolute;
  z-index: 3;
`;

export const CardItemInfo = styled.div`
  padding: 20px 30px 30px;
`;

export const CardItemText = styled.h5`
  color: #6e6f6f;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

export const CardItemTextGreen = styled.h5`
  color: #369e3a;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  padding-top: 15px;
`;

export const CardBtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const CardButton = styled(Link)`
  border-radius: 50px;
  background: #6e6f6f;
  white-space: nowrap;
  padding: 12px 30px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    transition: 0.2s ease-in-out;
    background: #369e3a;
    color: #fff;
  }
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;
