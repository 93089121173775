import styled from "styled-components";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

export const PropertiesContainer = styled.div`
  // height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  margin-bottom: 120px;

  // @media screen and (max-width: 768px) {
  //   height: 1100px;
  // }

  // @media screen and (max-width: 480px) {
  //   height: 1300px;
  // }
`;

export const PropertiesWrapper = styled.div`
  // max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 30px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    width: 99vw;
    overflow-x: scroll;
    grid-gap: 10px;
  }

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
    justify-content: center;
    width: 97vw;
    overflow-x: scroll;
    grid-gap: 20px;
  }
`;

export const PropertiesH1 = styled.h1`
  font-size: 2.5rem;
  color: #369e3a;
  margin-bottom: 50px;
  // margin-top: 40px;
  align-content: center;

  @media screen and (max-width: 760px) {
    font-size: 1.5rem;
  }
`;

export const PropertiesCard = styled(Link)`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-raidus: 10px;
  max-height: 640px;
  width: 400px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  border: 3px solid #369e3a;
  border-radius: 15px;
  text-decoration: none;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const PropertiesImgWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export const PropertiesCardStatus = styled.div`
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  background: #369e3a;
  padding: 7px 14px;
  display: inline-block;
  border-radius: 1px;
  position: absolute;
  z-index: 3;
`;

export const PropertiesIcon = styled.img`
  max-height: 200px;
  width: 350px;
  margin-bottom: 10px;
`;

export const PropertyUnderOfferImg = styled.img`
  position: absolute;
  top: 15%;
  left: 25%;
  display: block;
  width: 50%;
  height: 70%;
  transition: all 0.2s linear;
`;

export const PropertiesH3 = styled.h3`
  padding-top: 20px;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 5px;
  color: #6e6f6f;
`;

export const PropertiesP = styled.p`
  margin-bottom: 0;
  color: #369e3a;
  font-size: 12px;
  margin-top: 10px;
`;

export const PropertiesSmallIcon = styled.i`
  color: #369e3a;
  margin-right: 10px;
`;

export const PropertiesInfo = styled.div`
  // border-left: 1px solid #ebebeb;
  // border-right: 1px solid #ebebeb;
  text-align: center;
  width: 100%;
`;

export const PropertyRoomInfoWrapper = styled.div`
  padding: 25px 0 0 0;
  padding-bottom: 0;
`;

export const PropertiesRoomInfo = styled.div`
  border-top: 1px solid #ebebeb;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
`;

export const PropertiesRoomInfoLeft = styled.div`
  float: left;
  text-align: left;
`;

export const PropertiesRoomInfoRight = styled.div`
  float: right;
  text-align: left;
`;

export const PropertiesRoomInfoLastRight = styled.div`
  float: right;
  text-align: left;
  // padding-right: 23px;
`;

export const PropertiesRoomInfoText = styled.p`
  margin-bottom: 15px;
  font-size: 10.9px;
  color: #707070;
`;

export const PropertiesRoomInfoIcon = styled.i`
  color: #369e3a;
  margin-right: 10px;
`;

export const PropertiesPrice = styled.a`
  display: block;
  font-size: 18px;
  font-weight: 600;
  background: #fff;
  text-align: center;
  padding: 13px;
  background: #369e3a;
  color: #fff;
`;

export const ProcessingImgWrapper = styled.img`
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
`;

export const InfoSectionBtnWrap = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  max-width: 800px;
  margin: 50px auto;
  justify-content: center;

  @media only screen and (max-width: 760px) {
    flex-flow: column;
    display: grid;
    // grid-template-columns: 1fr 1fr;
    max-width: 400px;
  }
`;

export const InfoSelectionButton = styled.button`
  border-radius: 50px;
  min-width: 200px;
  background: #6e6f6f;
  white-space: nowrap;
  padding: 10px 22px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: 0.2s ease-in-out;
    background: #369e3a;
    color: #010606;
  }

  @media only screen and (max-width: 760px) {
    margin-bottom: 0.5rem;
  }
`;

export const InfoSelectionButtonGreen = styled.button`
  border-radius: 50px;
  min-width: 200px;
  // background: ${({ buttonColor }) => (buttonColor ? "#369e3a" : "#000")};
  background: #369e3a;
  white-space: nowrap;
  padding: 10px 22px;
  color: #000;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  @media only screen and (max-width: 760px) {
    margin-bottom: 0.5rem;
  }
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

export const MorePropertiesButtonWrapper = styled.div`
  padding: 110px 0 0 0;
  max-height: 150px;
  text-align: center;
`;

export const MorePropertiesLoaderWrapper = styled.div`
  padding: 80px 0 0 0;
  max-height: 150px;
  text-align: center;
`;

export const MorePropertiesButton = styled.button`
  border-radius: 50px;
  background: #369e3a;
  padding: 12px 30px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: 0.2s ease-in-out;
  }
`;
