import React, { useState } from "react";
import { Button } from "../ButtonElements";
import Popup from "../popup";
import ContactUsEmail from "../contactus/contactUsEmail";

import {
  OurTeamInfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  SubTitle,
  BtnWrap,
  ImgWrap,
  Img,
  DetailLink,
  CallButton,
} from "./InfoElements";

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  darkText,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
  profilePic,
  // completeDescription,
  phoneNumber,
  emailId,
  firstName,
}) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [isEmailOpen, setEmailIsOpen] = useState(false);

  // const togglePopup = (e) => {
  //   e.preventDefault();
  //   setIsOpen(!isOpen);
  // };

  const toggleEmailPopup = (e) => {
    e.preventDefault();
    setEmailIsOpen(!isEmailOpen);
  };

  return (
    <>
      <OurTeamInfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <SubTitle darkText={darkText}>{description}...</SubTitle>
                <DetailLink to={`/teamprofile/${id}`}>
                  Click here to view more...
                </DetailLink>
                {/* {isOpen && ( */}
                {/* <Popup */}
                {/* content={ */}
                {/* <> */}
                {/* <b> */}
                {/* {headline} */}
                {/* <br /> */}
                {/* <br /> */}
                {/* </b> */}
                {/* <p>{completeDescription}</p> */}
                {/* <button>Test button</button> */}
                {/* </> */}
                {/* } */}
                {/* handleClose={togglePopup} */}
                {/* /> */}
                {/* )} */}
                <BtnWrap>
                  <Button
                    onClick={toggleEmailPopup}
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={0}
                    dark2={dark2 ? 1 : 0}
                  >
                    {buttonLabel}
                  </Button>
                  {isEmailOpen && (
                    <Popup
                      content={
                        <>
                          <ContactUsEmail
                            agent_email={emailId}
                            agent_name={firstName}
                          />
                          {/* <button>Test button</button> */}
                        </>
                      }
                      handleClose={toggleEmailPopup}
                    />
                  )}
                  <Button
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    dark={dark ? 1 : 0}
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    <CallButton href={phoneNumber}>Call Now</CallButton>
                  </Button>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt} profilePic={profilePic} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </OurTeamInfoContainer>
    </>
  );
};

export default InfoSection;
