export const rajeshsetia = {
  id: "rajeshsetia",
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Principal / Director",
  headline: "Rajesh Setia",
  description:
    "Rajesh Setia, the founder and Principal Director, was inspired by his late grandfather, a successful businessman, who taught Rajesh that the most important human expression is “good-will” and wanting the best for everyone.",
  completeDescription:
    "Rajesh Setia, the founder and Principal Director, was inspired by his late grandfather, a successful businessman, who taught Rajesh that the most important human expression is “good-will” and wanting the best for everyone. \n\nRajesh Setia is part of the team at Setia Real Estate located at Shop 3B/60-68 Rosenthal Street, Doonside NSW. For all your local property needs in this area, get in touch with Rajesh.\n\nRajesh has taken these important lessons and has made them the core values in both his life and at Setia Real Estate. Rajesh epitomizes integrity, energy, hard work and creative service in every details of your real estate transaction.\n\nAs a family man and father of two young children, he knows the value and importance of owning a home and believes in providing trustworthy advice and strives to maintain long-term relationships with his clients. He enjoys helping families to improve their life by buying, selling and investing in property.\n\nHe has a Diploma in Marketing Management and a Degree in Information Business Systems (CQU), which help his clients with traditional and modern marketing for their property. Rajesh Setia has over 8 years’ experience in real estate leadership and success across Sydney.\n\nRate my Agent, a leading and prestigious property forum, has awarded Rajesh “Agent of the Year” for 2018, 2019, 2020 and 2021. Rajesh’s record sales, and “agent of the year” success, has largely been achieved as a result of his negotiation skills and customer satisfaction.",
  buttonLabel: "Send Email",
  buttonLink: "",
  imgStart: true,
  img: "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/assets/team/profile_picture_rajesh_setia.jpg",
  profilePic: true,
  alt: "Rajesh Setia",
  dark: true,
  primary: false,
  darkText: true,
  phoneNumber: "tel:0423-480-737",
  emailId: "rajesh.s@setiarealestate.com.au",
  firstName: "Rajesh",
  contactNumber: "0423-480-737",
};

export const hemasetia = {
  id: "hemasetia",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Director / Office Manager",
  headline: "Hema Setia",
  description:
    "Hema’s passion for real estate comes from a background of investing in property and renovating properties.",
  completeDescription:
    "Hema’s passion for real estate comes from a background of investing in property and renovating properties. Hema believes in providing trustworthy advice and strives to maintain long-term relationships with her clients.\n\nHer wealth of knowledge attained over many years in Real Estate, along with her time management skills ensures that every client is obtaining the maximum possible return on their investment property.\n\nHema’s passion and youthful demeanour sets her apart from other agents. She works with her clients through every step and ensures that she meets their needs. A true professional, she is grateful to her clients for giving her a chance to present her skills and achieve their goals.\n\nAs a married mother of two young children, she enjoys helping families to improve their life by buying, selling and investing in property. Hema’s works closely with her property management team to ensure all clients gets the best customer service and they get the The Setia Experience.",
  buttonLabel: "Send Email",
  buttonLink: "",
  imgStart: false,
  img: "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/assets/team/profile_picture_hema_setia.jpg",
  profilePic: true,
  alt: "Hema Setia",
  dark: true,
  primary: false,
  darkText: true,
  phoneNumber: "tel:02-7202-9411",
  emailId: "hema.s@setiarealestate.com.au",
  firstName: "Hema",
  contactNumber: "02-7202-9411",
};

// export const sachinkhera = {
//   id: "sachinkhera",
//   lightBg: false,
//   lightText: false,
//   lightTextDesc: false,
//   topLine: "Sales Executive",
//   headline: "Sachin Khera",
//   description:
//     "Sachin brings the right mix of analytic skills, market expertise, energy, and a personal touch all to provide a holistic approach to real estate service.",
//   completeDescription:
//     "Sachin brings the right mix of analytic skills, market expertise, energy, and a personal touch all to provide a holistic approach to real estate service.\n\nHe specialises in residential home and urban condominium market. “My goal is to provide my clients excellent service beyond compare” said Sachin.\n\nHe is actively engaged in Local community. His business has been built on personal referrals he always seeks opportunities to provide new clients the same positive experience and results.",
//   buttonLabel: "Send Email",
//   buttonLink: "",
//   imgStart: true,
//   img: sachin_khera_profile,
//   profilePic: true,
//   alt: "Sachin Khera",
//   dark: true,
//   primary: false,
//   darkText: true,
//   phoneNumber: "tel:0430-753-071",
//   emailId: "sachin.k@setiarealestate.com.au",
//   firstName: "Sachin",
//   contactNumber: "0430-753-071",
// };

export const mannysingh = {
  id: "mannysingh",
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Sales Executive",
  headline: "Manny Singh",
  description:
    "With professionalism and client interest a top priority, Manny Singh is an honest and friendly Sales agent at Setia Real Estate with a solid reputation of providing exemplary customer service.",
  completeDescription:
    "With professionalism and client interest a top priority, Manny Singh is an honest and friendly Sales agent at Setia Real Estate with a solid reputation of providing exemplary customer service.Manny finds meeting new people and helping them to achieve their property goals an extremely rewarding aspect of his role.\n\nManny always strive to be proactive, customer-focused, transparent and reliable; put client’s interest ahead of his own and deliver extraordinary results. Working closely with his clients and their feedback has been backbone of his leaning experience.\n\nAs a team, our ability to provide planned marketing and property procurement support to clients has been helpful in acquiring extensive client base. With strong work ethic, fine attention to detail and empathetic nature, we constantly assure everyone a unique customer service.\n\nManny strength lies with great negotiation skills, and the ability to quickly connect the right people to the right property, also our working style puts both vendor and prospective buyer at ease.\n\nSo call Manny to say “Good day” or to discuss all of your real estate needs.",
  buttonLabel: "Send Email",
  buttonLink: "",
  imgStart: true,
  img: "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/assets/team/profile_picture_manny_singh.jpg",
  profilePic: true,
  alt: "Manny Singh",
  dark: true,
  primary: false,
  darkText: true,
  phoneNumber: "tel:0478-802-314",
  emailId: "manny.s@setiarealestate.com.au",
  firstName: "Manny",
  contactNumber: "0478-802-314",
};

export const khushwantgill = {
  id: "khushwantgill",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Sales Executive",
  headline: "Khushwant Gill",
  description:
    "Khushwant has a can-do attitude with excellent negotiation skills, Khushwant is focused on achieving the highest level of customer service.",
  completeDescription:
    "Khushwant has a can-do attitude with excellent negotiation skills, Khushwant is focused on achieving the highest level of customer service. Understanding the importance of buyers and sellers, Khushwant is always sure to update his clients with all the latest market information being needed.\n\nKhushwant attributes his success not only to his passion for homes but also to his intuition regarding the psychology of buying and selling. As a real estate agent, he continually invests time, money, and thought into the latest sales, marketing and negotiations techniques. He is passionate about real estate and works hard to achieve his client’s goals.\n\nHe loves dealing with people and assisting them with their Real Estate Journey. Outstanding skills and work ethic and a solid knowledge of the market have built a list of loyal customers who would turn to him for real estate advice.",
  buttonLabel: "Send Email",
  buttonLink: "",
  imgStart: false,
  img: "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/assets/team/profile_picture_khushwant_gill.jpg",
  profilePic: true,
  alt: "Khushwant Gill",
  dark: true,
  primary: false,
  darkText: true,
  phoneNumber: "tel:0430-458-660",
  emailId: "khushwant.g@setiarealestate.com.au",
  firstName: "Khushwant",
  contactNumber: "0430-458-660",
};

export const pankajsharma = {
  id: "pankajsharma",
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Sales Executive",
  headline: "Pankaj Sharma",
  description:
    "Pankaj prides himself on his honesty and dedication to helping families to achieve the highest possible price for their homes.",
  completeDescription:
    "Pankaj prides himself on his honesty and dedication to helping families to achieve the highest possible price for their homes. Pankaj has a determined focus to provide all his clients premium results and a consistent level of service that is above and beyond their expectations, through integrity, trust and genuine hard work.\n\nSelling your home can be a stressful and emotional time. Pankaj’s aim is to ensure that the entire selling process is as stress-free as possible thanks to a high level of care and dedication. The ability to understand his clients’ needs and respond to queries and concerns promptly and with confidence are core values he upholds with every valued client.\n\nHaving lived in the Sydney suburbs for most of his life, provides Pankaj the ability to utilise his local knowledge to closely monitor the market and recommend the best strategy to achieve your real estate goals.",
  buttonLabel: "Send Email",
  buttonLink: "",
  imgStart: true,
  img: "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/assets/team/profile_picture_pankaj_sharma.jpeg",
  profilePic: true,
  alt: "Pankaj Sharma",
  dark: true,
  primary: false,
  darkText: true,
  phoneNumber: "tel:0411 571 422",
  emailId: "pankaj.s@setiarealestate.com.au",
  firstName: "Pankaj",
  contactNumber: "0411-571-422",
};

export const michaeltorrisi = {
  id: "michaeltorrisi",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Persoanl Assistant to Rajesh Setia",
  headline: "Michael Torrisi",
  description:
    "An invaluable team member of Setia Real Estate Michael is a confident self-starter and a motivated employee who jumps at the opportunity to best cater needs of owners with leasing their biggest assets in mind.",
  completeDescription:
    "An invaluable team member of Setia Real Estate Michael is a confident self-starter and a motivated employee who jumps at the opportunity to best cater needs of owners with leasing their biggest assets in mind.  Michael's efficient, multi-tasking and friendly personal approach provide some of many ingredients to exceptional customer service.\n\nMichael is a committed father who has worked in several areas of Sydney breathing 7 years of experience in the real estate industry.  Being a family man at heart his life centres around his young children whom he utterly adores.\n\nWhether being considerate to the needs of others or analytically solving a problem with the owner/s and tenant/s in mind Michael excels at separating emotions from the task at hand to deliver an effective solution. Intrigued by tenants’ circumstances Michael makes the effort not to compromise a tenant/s rental property requirement by successfully matching the right property to the right tenant/s.\n\nCommunicating effectively and building solid relationships with both tenants and landlords he is a stellar property officer striving to make a difference in the real estate profession.\n\nIn the sales arena at Setia Real estate Michael takes on the nothing too big approach with Principal Sales agent and founder of the business Rajesh Setia in going above and beyond expectations of both prospective buyer/s and seller/s. Assisting open homes Michael ensures prospective buyer/s are well informed about the property and on the occasion, he is unable to provide an answer he will source one for you.",
  buttonLabel: "Send Email",
  buttonLink: "",
  imgStart: false,
  img: "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/assets/team/profile_picture_michael_torrisi.jpg",
  profilePic: true,
  alt: "Michael Torrisi",
  dark: true,
  primary: false,
  darkText: true,
  phoneNumber: "tel:0478-008-188",
  emailId: "michael.t@setiarealestate.com.au",
  firstName: "Michael",
  contactNumber: "0478-008-188",
};

export const shivangipawar = {
  id: "shivangipawar",
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Persoanl Assistant to Rajesh Setia",
  headline: "Shivangi Pawar",
  description: "",
  completeDescription: "",
  buttonLabel: "Send Email",
  buttonLink: "",
  imgStart: true,
  img: "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/assets/team/profile_picture_shivangi_pawar.jpg",
  profilePic: true,
  alt: "Shivangi Pawar",
  dark: true,
  primary: false,
  darkText: true,
  phoneNumber: "tel:02-7202-9411",
  emailId: "office@setiarealestate.com.au",
  firstName: "Shivangi",
  contactNumber: "02-7202-9411",
};
