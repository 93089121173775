import React, { useState } from "react";
import Sidebar from "../sidebar";
import Navbar from "../navbar";
import HeroSection from "../herosection";
import InfoSection from "../infosection";
import { homeObjOne, homeObjTwo, homeObjThree } from "../infosection/Data";
// import Services from "../services";
import Footer from "../footer";
import CardSection from "../cardsection";
import ScrollToTop from "../ScrollToTop";

const Home = () => {
  const [isOpen, setisOpen] = useState(false);

  const toggle = () => {
    setisOpen(!isOpen);
  };

  return (
    <>
      <ScrollToTop />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      {/* <InfoSection {...homeObjOne} /> */}
      <CardSection />
      <InfoSection {...homeObjOne} />
      <InfoSection {...homeObjTwo} />
      <InfoSection {...homeObjThree} />
      {/* <Services /> */}
      <Footer />
    </>
  );
};

export default Home;
