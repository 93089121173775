import React, { useState } from "react";
import { Button } from "../ButtonElements";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  // TopLine,
  // Heading,
  Headings,
  HeadingGreen,
  HeadingGrey,
  HeadingGreen2,
  SubTitle,
  BtnWrap,
  ImgWrap,
  Img,
  ArrowForward,
  ArrowRight,
} from "./InfoElements";

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  darkText,
  headline1,
  headline2,
  headline3,
  headline4,
  description,
  buttonLabel,
  buttonLink,
  img,
  alt,
  primary,
  dark,
  dark2,
  profilePic,
}) => {
  const [hover, setState] = useState(false);
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                {/* <TopLine>{topLine}</TopLine> */}
                <Headings>
                  <HeadingGreen>{headline1}</HeadingGreen>
                  <HeadingGrey>{headline2}</HeadingGrey>
                  {headline3 ? <HeadingGreen2>{headline3}</HeadingGreen2> : ""}
                  {headline4 ? <HeadingGrey>{headline4}</HeadingGrey> : ""}
                </Headings>
                <SubTitle darkText={darkText}>{description}...</SubTitle>
                <BtnWrap>
                  {buttonLink ? (
                    <Button
                      to={buttonLink}
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      primary={primary ? 1 : 0}
                      dark={dark ? 1 : 0}
                      dark2={dark2 ? 1 : 0}
                      onMouseEnter={() => setState(true)}
                      onMouseLeave={() => setState(false)}
                    >
                      {buttonLabel}
                      {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                  ) : (
                    ""
                  )}
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt} profilePic={profilePic} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
