// import React, { useState } from "react";
import React from "react";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroIcon,
  HeroBtnWrapper,
  // ArrowForward,
  // ArrowRight,
} from "./HeroElements";
// import { Button } from "../ButtonElements";

const Video =
  "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/videos/video.mp4";
const hero_icon =
  "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/assets/static/Setia_real_estate_hero_logo.png";

const HeroSection = () => {
  // const [hover, setHover] = useState(false);

  // const onHover = () => {
  //   setHover(!hover);
  // };

  return (
    <HeroContainer>
      <HeroBg>
        <VideoBg playsinline autoPlay loop muted src={Video} type="video/mp4" />
      </HeroBg>
      <HeroContent>
        <HeroH1>
          <HeroIcon src={hero_icon} alt="Header Logo" />
        </HeroH1>
        <HeroP>Find Another Place, You Can Call Home</HeroP>
        <HeroBtnWrapper>
          {/* <Button
            to="signup"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
          >
            Get Started {hover ? <ArrowForward /> : <ArrowRight />}
          </Button> */}
          02 7202 9411
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
