import React, { useState } from "react";
import Sidebar from "../sidebar/onlyHomeIndex";
import Navbar from "../navbar/onlyHomeIndex";
import Footer from "../footer";
import ScrollToTop from "../ScrollToTop";
import AboutUsPage from "../aboutus";

const AboutUs = () => {
  const [isOpen, setisOpen] = useState(false);

  const toggle = () => {
    setisOpen(!isOpen);
  };
  return (
    <>
      <ScrollToTop />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <AboutUsPage />
      <Footer />
    </>
  );
};

export default AboutUs;
