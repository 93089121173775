import styled from "styled-components";

export const ContactUsContainer = styled.div``;

export const ContactUsEmailContainer = styled.div`
  width: 100%;
  height: 500px;
`;

export const ContactInfoContainer = styled.div`
  background: rgb(51, 153, 51);
  padding: 30px;
  text-align: center;
  // margin-bottom: 100px;

  display: grid;
  grid-template-columns: auto auto auto;

  @media screen and (max-width: 1100px) {
    font-size: 15px;
  }

  @media screen and (max-width: 768px) {
    font-size: 15px;
    grid-template-columns: auto;
  }
`;

export const ContactInfoParagraph = styled.p`
  font-size: 18px;
  color: #fff;
  display: inline-block;

  @media screen and (max-width: 799px) {
    font-size: 15px;
    margin-bottom: 15px;
  }
`;

export const ContactInfoParagraphLast = styled.p`
  font-size: 18px;
  color: #fff;
  display: inline-block;

  @media screen and (max-width: 799px) {
    font-size: 15px;
  }
`;

export const ContactInfoParagraphIcon = styled.i`
  margin-right: 10px;
`;

export const ContactUsFormContainer = styled.div`
  color: #fff;
  background: #f9f9f9;

  @media screen and (max-width: 768px) {
    padding: 100px 0;
    height: 1200px;
  }
`;

export const ContactUsEmailFormContainer = styled.div`
  color: #fff;
  background: #f9f9f9;
`;

export const ContactUsFormWrapper = styled.div`
  display: flex;
  z-index: 1;
  height: 860px;
  width: 100%;
  // max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  padding: 4rem 0rem;
  justify-content: center;
`;

export const ContactUsEmailWrapper = styled.div`
  display: fixed;
  z-index: 1;
  // height: auto;
  // width: 70%;
  // max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  // padding: 24px 24px;
  // padding: 4rem 0rem;
  // justify-content: center;
`;

export const ContactUsFormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 3rem calc((100vw - 1300px) / 2);
  align-items: center;
  grid-template-areas: "col1 col2";

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas: "col1" "col2";
  }
`;

export const ContactUsColumn1 = styled.div`
  margin-bottom: 120px;
  padding: 0 15px;
  grid-area: col1;
  text-align: center;

  @media screen and (max-width: 768px) {
    margin-top: -70px;
  }
`;

export const ContactUsFormImageWrap = styled.div`
  margin-width: 555px;
  height: 100%;
`;

export const Img = styled.img`
  width: 70%;
  height: 70%;
  margin: 0 0 10px 0;
  padding-right: 0px;
`;

export const ContactUsColumn2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const Form = styled.form`
  padding: 29px;
  border: 1px solid #ebebeb;
  border-radius: 1px;
  margin-bottom: 80px;
`;

export const FormH1 = styled.h1`
  margin-bottom: 30px;
  line-height: 1.1;
  font-weight: 600;
  font-weight: 400;
  color: #369e3a;

  @media screen and (max-width: 768px) {
    font-size: 25px;
    text-align: center;
  }
`;

export const TextBoxName = styled.input`
  width: 100%;
  border: 1px solid #ebebeb;
  margin-bottom: 17px;
  height: 36px;
  padding: 0 20px;
`;

export const TextBoxEmail = styled.input`
  width: 100%;
  border: 1px solid #ebebeb;
  margin-bottom: 17px;
  height: 36px;
  padding: 0 20px;
`;

export const TextBoxPhone = styled.input`
  width: 100%;
  border: 1px solid #ebebeb;
  margin-bottom: 17px;
  height: 36px;
  padding: 0 20px;
`;

export const TextArea = styled.textarea`
  height: 100px;
  padding: 10px 20px;
  width: 100%;
  border: 1px solid #ebebeb;
  margin-bottom: 17px;
`;

export const FormButton = styled.button`
  background: #369e3a;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border: none;
  min-width: 80px;
  height: 30px;
  border-radius: 1px;
  cursor: pointer;
`;

export const FormLabel = styled.label`
  font-size: 20px;
  color: #6e6f6f;
`;

export const FormRow1 = styled.div`
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
export const FormRow2 = styled.div`
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const FormColumn1 = styled.div`
  @media screen and (max-width: 768px) {
    text-align: center;
    margin-bottom: 20px;
  }
`;
export const FormColumn2 = styled.div``;

export const FormInput = styled.input`
  margin-left: 20px;
  font-size: 1em;
  border: 2px solid #369e3a;
  border-radius: 5px;
  height: 40px;
  width: 400px;
  text-align: center;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 90%;
  }
`;

export const FormEmailInput = styled.input`
  margin-left: 20px;
  font-size: 1em;
  border: 2px solid #369e3a;
  border-radius: 5px;
  height: 40px;
  width: 400px;
  text-align: center;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    width: 90%;
  }
`;

export const FormText = styled.textarea`
  margin-left: 20px;
  font-size: 1em;
  border: 2px solid #369e3a;
  border-radius: 5px;
  height: 40px;
  width: 400px;
  text-align: center;
  padding: 3rem;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const EmailFormText = styled.textarea`
  margin-left: 20px;
  font-size: 1em;
  border: 2px solid #0cbf71;
  border-radius: 5px;
  height: 40px;
  width: 400px;
  text-align: center;
  padding: 3rem;

  @media screen and (max-width: 768px) {
    width: 90%;
    margin-left: 0;
  }
`;

// export const FormButton = styled.button`
//   border-radius: 50px;
//   background: #6e6e6e;
//   white-space: nowrap;
//   padding: 10px 22px;
//   color: #fff;
//   font-size: 16px;
//   outline: none;
//   border: none;
//   cursor: pointer;
//   transition: all 0.2s ease-in-out;
//   text-decoration: none;
//   width: 100%;

//   &:hover {
//     transition: 0.2s ease-in-out;
//     background: #01bf71;
//     color: #010606;
//   }

//   @media screen and (max-width: 768px) {
//     width: 90%;
//   }
// `;

export const FormButtonWrap = styled.div`
  text-align: center;
  margin-top: 40px;
`;
