import React from "react";
import emailjs from "@emailjs/browser";

import {
  ContactUsEmailContainer,
  ContactUsEmailFormContainer,
  ContactUsEmailWrapper,
  Form,
  FormH1,
  TextBoxName,
  TextBoxEmail,
  TextBoxPhone,
  TextArea,
  FormButton,
} from "./ContactUsElements";

export const ContactUsEmail = ({ agent_email, agent_name }) => {
  // const form = useRef();

  const resetForm = () => {
    document.getElementById("emailForm").reset();
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const { agent_email, agent_name, name, email, phone, message } =
      e.target.elements;

    var templateParams = {
      agent_email: agent_email.value,
      agent_name: agent_name.value,
      from_name: name.value,
      from_email: email.value,
      phone: phone.value,
      message: message.value,
    };
    console.log(templateParams);
    emailjs
      .send(
        "service_g0xo4se",
        "template_u4x416i",
        templateParams,
        "fCYrp4AAfFBwtwTHX"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          emailjs
            .send(
              "service_g0xo4se",
              "template_er4ndy4",
              templateParams,
              "fCYrp4AAfFBwtwTHX"
            )
            .then(
              function (response) {
                console.log("SUCCESS!", response.status, response.text);
              },
              function (error) {
                console.log("FAILED...", error);
              }
            );
          alert("Your message has been successfully sent!");
          resetForm();
        },
        function (error) {
          console.log(error);
          console.log("FAILED...", error);
          alert(
            "Sorry, your message could not be delivered! Please try again later"
          );
          resetForm();
        }
      );
  };
  return (
    <ContactUsEmailContainer>
      <ContactUsEmailFormContainer>
        <ContactUsEmailWrapper>
          <Form id="emailForm" onSubmit={sendEmail}>
            <TextBoxName
              name="agent_email"
              type="hidden"
              value={agent_email}
            ></TextBoxName>
            <TextBoxName
              name="agent_name"
              type="hidden"
              value={agent_name}
            ></TextBoxName>
            <FormH1>Send us a message</FormH1>
            <TextBoxName
              name="name"
              type="text"
              placeholder="Your name"
            ></TextBoxName>
            <TextBoxEmail
              type="text"
              name="email"
              placeholder="Your email"
            ></TextBoxEmail>
            <TextBoxPhone
              type="text"
              name="phone"
              placeholder="Your phone number"
            ></TextBoxPhone>
            <TextArea name="message" placeholder="Your question"></TextArea>
            <FormButton type="submit">SEND</FormButton>
          </Form>
        </ContactUsEmailWrapper>
      </ContactUsEmailFormContainer>
    </ContactUsEmailContainer>
  );
};

export default ContactUsEmail;
