import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/pages";
import "./App.css";
import SigninPage from "./components/pages/signin";
import Properties from "./components/pages/properties";
import ContactUsAppraisal from "./components/pages/contactusforappraisal";
import AboutUs from "./components/pages/aboutus";
import OurTeam from "./components/pages/ourteam";
import TeamProfile from "./components/pages/teamprofile";
import Property from "./components/pages/property";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/signin" component={SigninPage} exact />
        <Route path="/properties" component={Properties} exact />
        <Route
          path="/contactusappraisal"
          component={ContactUsAppraisal}
          exact
        />
        <Route path="/aboutus" component={AboutUs} exact />
        <Route path="/ourteam" component={OurTeam} exact />
        <Route path="/teamprofile/:agentname" component={TeamProfile} exact />
        <Route
          path="/property/:propertyid/:typeofproperty"
          component={Property}
          exact
        />
      </Switch>
    </Router>
  );
}

export default App;
