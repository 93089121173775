import React from "react";

import {
  BannerContainer,
  BannerHeading,
  BannerContent,
  BannerImage,
} from "./BannerElements";

const Banner = ({ banner_img, banner_alt, banner_heading }) => (
  <BannerContainer>
    <BannerImage src={banner_img} alt={banner_alt}></BannerImage>
    <BannerContent>
      <BannerHeading>{banner_heading}</BannerHeading>
    </BannerContent>
  </BannerContainer>
);

export default Banner;
