import React, { useState } from "react";
import Sidebar from "../sidebar/onlyHomeIndex";
import Navbar from "../navbar/onlyHomeIndex";
import Footer from "../footer";
import PropertiesList from "../properties";
import ScrollToTop from "../ScrollToTop";

const Properties = () => {
  const [isOpen, setisOpen] = useState(false);

  const toggle = () => {
    setisOpen(!isOpen);
  };

  return (
    <>
      <ScrollToTop />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <PropertiesList />
      {/* <InfoSection {...homeObjTwo} />
      <Services />
      <InfoSection {...homeObjThree} /> */}
      <Footer />
    </>
  );
};

export default Properties;
