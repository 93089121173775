import styled from "styled-components";

export const AboutUsContainer = styled.div``;

export const AboutUsDetails = styled.div`
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 700px;

  @media screen and (max-width: 480px) {
    height: 900px;
  }
`;

export const AboutUsP = styled.p`
  color: #000;
  max-width: 900px;
  white-space: pre-line;
  font-size: 16px;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;
