import React from "react";
import GoogleMapReact from "google-map-react";
import { Icon } from "@iconify/react";
import LocationImage from "@iconify/icons-mdi/map-marker";

import {
  LocationIcon,
  LocationPointer,
  LocationText,
  MapContainer,
  MapHeading,
  MapBanner,
} from "./MapElements";

const LocationPin = ({ text }) => (
  <LocationPointer>
    <LocationIcon>
      <Icon icon={LocationImage} />
    </LocationIcon>

    <LocationText>{text}</LocationText>
  </LocationPointer>
);

const Map = ({ location, zoomLevel }) => (
  <MapContainer>
    <MapHeading>Come and meet us in our office</MapHeading>

    <MapBanner className="google-map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyB9vwGjHl0_D27HJzBaVqXdWqgcwv0KTqY" }}
        defaultCenter={location}
        defaultZoom={zoomLevel}
      >
        <LocationPin
          lat={location.lat}
          lng={location.lng}
          text={location.address}
        />
      </GoogleMapReact>
    </MapBanner>
  </MapContainer>
);

export default Map;
