import React from "react";
import { withRouter } from "react-router";
import axios from "axios";
import NumberFormat from "react-number-format";
import emailjs from "@emailjs/browser";

import {
  PropertyInfoProcessingContainer,
  PropertyInfoContainer,
  PropertyContainer,
  CarouselWrapper,
  DetailWrapper,
  AddressDetail,
  Column1,
  Column2,
  AddressDetailHeading,
  AddressDetailSuburb,
  PropertyDetailSuburbIcon,
  PriceDetail,
  PropertyDetailHeading,
  PropertyDetail,
  PropertyColumn1,
  PropertyColumn2,
  PropertyColumn3,
  PropertyDescription,
  PropertyContactContainer,
  PropertyContact,
  PropertyContactImage,
  PropertyContactName,
  PropertyContactLastName,
  PropertyContactDetails,
  PropertyContactInfoDetails,
  PropertyContactCompany,
  PropertyContactEmail,
  PropertyContactPhone,
  PropertyContactForm,
  FormH1,
  TextBoxName,
  TextBoxEmail,
  TextBoxPhone,
  TextArea,
  FormButton,
  ProcessingImgWrapper,
} from "./PropertyElements";
import { Carousel } from "react-carousel-minimal";

// const API = "http://localhost:8080/";
const API = "https://proxysetiarealestate.com/";
const loading_properties =
  "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/animations/Processing-Icon.gif";
const image_unavailable =
  "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/assets/static/Image_unavailable.jpeg";

let data = [];

const captionStyle = {
  fontSize: "2em",
  fontWeight: "bold",
};
const slideNumberStyle = {
  fontSize: "20px",
  fontWeight: "bold",
};

const resetForm = () => {
  document.getElementById("emailForm").reset();
};

const sendEmail = (
  agent_id,
  agent_email,
  agent_name,
  name,
  email,
  phone,
  message
) => {
  var templateParams = {
    agent_id: agent_id,
    agent_email: agent_email,
    agent_name: agent_name,
    from_name: name,
    from_email: email,
    phone: phone,
    message: message,
  };
  emailjs
    .send(
      "service_g0xo4se",
      "template_u4x416i",
      templateParams,
      "fCYrp4AAfFBwtwTHX"
    )
    .then(
      function (response) {
        console.log("SUCCESS!", response.status, response.text);
        emailjs
          .send(
            "service_g0xo4se",
            "template_er4ndy4",
            templateParams,
            "fCYrp4AAfFBwtwTHX"
          )
          .then(
            function (response) {
              console.log("SUCCESS!", response.status, response.text);
            },
            function (error) {
              console.log("FAILED...", error);
            }
          );
        alert("Your message has been successfully sent!");
        resetForm();
      },
      function (error) {
        console.log("FAILED...", error);
        alert(
          "Sorry, your message could not be delivered! Please try again later"
        );
        resetForm();
      }
    );
};

class PropertyInfo extends React.Component {
  state = {
    loading: true,
    property: null,
    error: null,
    endpoint:
      this.props.match.params.typeofproperty === "forLease"
        ? "getLeaseProperty"
        : "getSaleProperty",
    images: [],
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const response = await axios.get(API + this.state.endpoint, {
        params: {
          propertyId: this.props.match.params.propertyid,
        },
      });
      data = [];
      if (response.data.photos.length > 0) {
        for (var i = 0; i < response.data.photos.length; i++) {
          data.push({
            image: response.data.photos[i].url,
            caption: ``,
          });
        }
      } else if (response.data.photos.length <= 0) {
        data.push({
          image: image_unavailable,
          caption: ``,
        });
      }
      this.setState({
        property: response.data,
        loading: false,
        images: data,
      });
    } catch (error) {
      this.setState({
        error: error,
        loading: true,
      });
    }
  }

  render() {
    function sendButtonClicked(e) {
      e.preventDefault();
      const { agent_id, agent_email, agent_name, name, email, phone, message } =
        e.target.elements;

      sendEmail(
        agent_id.value,
        agent_email.value,
        agent_name.value,
        name.value,
        email.value,
        phone.value,
        message.value
      );
    }

    return (
      <>
        {this.state.loading ? (
          <PropertyInfoProcessingContainer>
            <ProcessingImgWrapper
              src={loading_properties}
              alt="Loading Image"
            ></ProcessingImgWrapper>
          </PropertyInfoProcessingContainer>
        ) : (
          <PropertyInfoContainer>
            <PropertyContainer>
              <CarouselWrapper>
                <Carousel
                  data={this.state.images}
                  time={0}
                  width="100%"
                  height="500px"
                  captionStyle={captionStyle}
                  radius="0px"
                  slideNumber={false}
                  slideNumberStyle={slideNumberStyle}
                  captionPosition="bottom"
                  automatic={false}
                  dots={false}
                  pauseIconColor="white"
                  pauseIconSize="40px"
                  slideBackgroundColor="darkgrey"
                  slideImageFit="cover"
                  thumbnails={true}
                  thumbnailWidth="100px"
                  showNavBtn={true}
                  style={{
                    textAlign: "center",
                    maxWidth: "100%",
                    margin: "40px auto",
                  }}
                />
              </CarouselWrapper>
              <DetailWrapper>
                <AddressDetail>
                  <Column1>
                    <AddressDetailHeading>
                      {this.state.property.displayAddress.split(",")[0]}
                    </AddressDetailHeading>
                    <AddressDetailSuburb>
                      <PropertyDetailSuburbIcon>
                        <i class="fa fa-map-marker" />
                      </PropertyDetailSuburbIcon>
                      {this.state.property.address.suburb.name},{" "}
                      {this.state.property.address.suburb.postcode}
                    </AddressDetailSuburb>
                  </Column1>
                  <Column2>
                    <PriceDetail>
                      {this.state.property.status === "unconditional" ? (
                        <NumberFormat
                          value={this.state.property.saleHistory[0].salePrice}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$ "}
                        />
                      ) : this.state.property.displayPrice === "" ? (
                        <NumberFormat
                          value={this.state.property.searchPrice}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$ "}
                        />
                      ) : (
                        this.state.property.displayPrice
                      )}
                    </PriceDetail>
                  </Column2>
                </AddressDetail>
                <PropertyDetailHeading>Property Details</PropertyDetailHeading>
                <PropertyDetail>
                  <PropertyColumn1>
                    <PropertyDetailSuburbIcon>
                      <i class="fa fa-th-large"></i>
                    </PropertyDetailSuburbIcon>
                    {this.state.property.landArea.value} Square metre
                  </PropertyColumn1>
                  <PropertyColumn2>
                    <PropertyDetailSuburbIcon>
                      <i class="fa fa-bed"></i>
                    </PropertyDetailSuburbIcon>
                    {this.state.property.bed} Bedrooms
                  </PropertyColumn2>
                  <PropertyColumn3>
                    <PropertyDetailSuburbIcon>
                      <i class="fa fa-user"></i>
                    </PropertyDetailSuburbIcon>
                    {this.state.property.contactStaff[0].firstName}{" "}
                    {this.state.property.contactStaff[0].lastName}
                  </PropertyColumn3>
                </PropertyDetail>
                <PropertyDetail>
                  <PropertyColumn1>
                    <PropertyDetailSuburbIcon>
                      <i class="fa fa-car"></i>
                    </PropertyDetailSuburbIcon>
                    {this.state.property.bath} Garages
                  </PropertyColumn1>
                  <PropertyColumn2>
                    <PropertyDetailSuburbIcon>
                      <i class="fa fa-building-o"></i>
                    </PropertyDetailSuburbIcon>
                    {this.state.property.type.name}
                  </PropertyColumn2>
                  <PropertyColumn3>
                    <PropertyDetailSuburbIcon>
                      <i class="fa fa-clock-o"></i>
                    </PropertyDetailSuburbIcon>
                    Recently
                  </PropertyColumn3>
                </PropertyDetail>
                <PropertyDetail>
                  <PropertyColumn1>
                    <PropertyDetailSuburbIcon>
                      <i class="fa fa-bath"></i>
                    </PropertyDetailSuburbIcon>
                    {this.state.property.bath} Bathrooms
                  </PropertyColumn1>
                  <PropertyColumn2>
                    <PropertyDetailSuburbIcon>
                      <i class="fa fa-trophy"></i>
                    </PropertyDetailSuburbIcon>
                    5(?) years age
                  </PropertyColumn2>
                </PropertyDetail>
                <PropertyDetailHeading>Description</PropertyDetailHeading>
                <PropertyDescription>
                  {this.state.property.description}
                </PropertyDescription>
              </DetailWrapper>
            </PropertyContainer>
            <PropertyContactContainer>
              <PropertyContact>
                <PropertyContactImage
                  src={this.state.property.contactStaff[0].photo.thumb_360}
                  alt="hell"
                ></PropertyContactImage>
                <PropertyContactDetails>
                  <PropertyContactName>
                    {this.state.property.contactStaff[0].firstName}{" "}
                    <PropertyContactLastName>
                      {this.state.property.contactStaff[0].lastName}
                    </PropertyContactLastName>
                  </PropertyContactName>
                  <PropertyContactCompany>
                    {this.state.property.contactStaff[0].position}
                  </PropertyContactCompany>
                </PropertyContactDetails>
                <PropertyContactInfoDetails>
                  <PropertyContactEmail>
                    <PropertyDetailSuburbIcon>
                      <i class="fa fa-envelope"></i>
                    </PropertyDetailSuburbIcon>
                    {this.state.property.contactStaff[0].email}
                  </PropertyContactEmail>
                  <PropertyContactPhone>
                    <PropertyDetailSuburbIcon>
                      <i class="fa fa-phone"></i>
                    </PropertyDetailSuburbIcon>
                    {this.state.property.contactStaff[0].phoneNumbers[0].number}
                  </PropertyContactPhone>
                </PropertyContactInfoDetails>
              </PropertyContact>
              {this.state.property.contactStaff[1] ? (
                <PropertyContact>
                  <PropertyContactImage
                    src={this.state.property.contactStaff[1].photo.thumb_360}
                    alt="hell"
                  ></PropertyContactImage>
                  <PropertyContactDetails>
                    <PropertyContactName>
                      {this.state.property.contactStaff[1].firstName}{" "}
                      <PropertyContactLastName>
                        {this.state.property.contactStaff[1].lastName}
                      </PropertyContactLastName>
                    </PropertyContactName>
                    <PropertyContactCompany>
                      {this.state.property.contactStaff[1].position}
                    </PropertyContactCompany>
                  </PropertyContactDetails>
                  <PropertyContactInfoDetails>
                    <PropertyContactEmail>
                      <PropertyDetailSuburbIcon>
                        <i class="fa fa-envelope"></i>
                      </PropertyDetailSuburbIcon>
                      {this.state.property.contactStaff[1].email}
                    </PropertyContactEmail>
                    <PropertyContactPhone>
                      <PropertyDetailSuburbIcon>
                        <i class="fa fa-phone"></i>
                      </PropertyDetailSuburbIcon>
                      {
                        this.state.property.contactStaff[1].phoneNumbers[0]
                          .number
                      }
                    </PropertyContactPhone>
                  </PropertyContactInfoDetails>
                </PropertyContact>
              ) : (
                " "
              )}
              <PropertyContactForm id="emailForm" onSubmit={sendButtonClicked}>
                <FormH1>Do you have any question?</FormH1>
                <TextBoxName
                  name="agent_id"
                  type="hidden"
                  value={this.state.property.contactStaff[0].id}
                ></TextBoxName>
                <TextBoxName
                  name="agent_email"
                  type="hidden"
                  value={this.state.property.contactStaff[0].email}
                ></TextBoxName>
                <TextBoxName
                  name="agent_name"
                  type="hidden"
                  value={this.state.property.contactStaff[0].firstName}
                ></TextBoxName>
                <TextBoxName
                  name="name"
                  type="text"
                  placeholder="Your name"
                ></TextBoxName>
                <TextBoxEmail
                  type="text"
                  name="email"
                  placeholder="Your email"
                ></TextBoxEmail>
                <TextBoxPhone
                  type="text"
                  name="phone"
                  placeholder="Your phone number"
                ></TextBoxPhone>
                <TextArea name="message" placeholder="Your question"></TextArea>
                <FormButton type="submit">SEND</FormButton>
              </PropertyContactForm>
            </PropertyContactContainer>
          </PropertyInfoContainer>
        )}
      </>
    );
  }
}

export default withRouter(PropertyInfo);
