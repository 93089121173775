import React from "react";
import axios from "axios";
// import loading_properties from "../../images/loading_properties.gif";

import {
  CardContainer,
  CardWrapper,
  CardItems,
  CardItem,
  CardItemLink,
  ProcessingImgWrapper,
  CardItemImgWrapper,
  CardItemImg,
  CardItemUnderOfferImg,
  CardStatus,
  CardItemInfo,
  CardItemText,
  CardItemTextGreen,
  Card,
  CardTitle,
  CardBtnWrap,
  CardButton,
  InfoSectionBtnWrap,
  InfoSelectionButton,
  InfoSelectionButtonGreen,
  ArrowForward,
  ArrowRight,
} from "./CardElements";
import properties from "../properties";

// const API = "http://localhost:8080/";
const API = "https://proxysetiarealestate.com/";
const loading_properties =
  "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/animations/Processing-Icon.gif";
const image_unavailable =
  "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/assets/static/Image_unavailable.jpeg";
const image_under_offer =
  "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/assets/static/under_offer_logo.png";

class CardSection extends React.Component {
  state = {
    loading: true,
    properties: null,
    error: null,
    // endpoint: "propertiesforsale",
    endpoint: "getPropertiesForSale",
    infoSelection: null,
    toBuyClick: true,
    toRentClick: false,
    soldClick: false,
    offTheMarketClick: false,
    hover: false,
    status: "listing,conditional",
    sort: "inserted",
    sortOrder: "desc",
    published: "true",
  };

  changeInfoSelection = (e) => {
    this.setState({ isLoading: true });
    if (e.target.innerHTML === "Rent") {
      this.setState(
        {
          toRentClick: true,
          soldClick: false,
          offTheMarketClick: false,
          toBuyClick: false,
          status: "listing",
          endpoint: "getPropertiesForLease",
          sort: "modified",
          loading: true,
        },
        this.makeAxiosRequest
      );
    } else if (e.target.innerHTML === "Sold") {
      this.setState(
        {
          toRentClick: false,
          soldClick: true,
          offTheMarketClick: false,
          toBuyClick: false,
          status: "unconditional",
          endpoint: "getSoldProperties",
          sort: "unconditional",
          loading: true,
        },
        this.makeAxiosRequest
      );
    } else if (e.target.innerHTML === "Off Market") {
      this.setState(
        {
          toRentClick: false,
          soldClick: false,
          offTheMarketClick: true,
          toBuyClick: false,
          status: "listing",
          published: "false",
          endpoint: "getPropertiesForSale",
          sort: "inserted",
          loading: true,
        },
        this.makeAxiosRequest
      );
    } else if (e.target.innerHTML === "Buy") {
      this.setState(
        {
          toRentClick: false,
          soldClick: false,
          offTheMarketClick: false,
          toBuyClick: true,
          status: "listing,conditional",
          published: "true",
          endpoint: "getPropertiesForSale",
          sort: "inserted",
          loading: true,
        },
        this.makeAxiosRequest
      );
    }
  };

  makeAxiosRequest = async (dispatch) => {
    try {
      const response = await axios.get(API + this.state.endpoint, {
        params: {
          status: this.state.status,
          sort: this.state.sort,
          sortOrder: this.state.sortOrder,
          published: this.state.published,
        },
      });

      this.setState({
        properties: response.data.items,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        error: error,
        loading: false,
      });
    }
  };

  async componentDidMount() {
    // axios.interceptors.request.use((request) => {
    //   console.log("Starting Request", JSON.stringify(request, null, 2));
    //   return request;
    // });

    // axios.interceptors.response.use((response) => {
    //   console.log("Response:", JSON.stringify(response, null, 2));
    //   return response;
    // });

    this.setState({ isLoading: true });

    try {
      const response = await axios.get(API + this.state.endpoint, {
        params: {
          status: this.state.status,
          sort: this.state.sort,
          sortOrder: this.state.sortOrder,
          published: this.state.published,
        },
      });

      this.setState({
        properties: response.data.items,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error: error,
        loading: false,
      });
    }
  }

  render() {
    const BuyPropertiesButton = this.state.toBuyClick
      ? InfoSelectionButtonGreen
      : InfoSelectionButton;
    const RentPropertiesButton = this.state.toRentClick
      ? InfoSelectionButtonGreen
      : InfoSelectionButton;
    const SoldPropertiesButton = this.state.soldClick
      ? InfoSelectionButtonGreen
      : InfoSelectionButton;
    const OffTheMarketPropertiesButton = this.state.offTheMarketClick
      ? InfoSelectionButtonGreen
      : InfoSelectionButton;

    return (
      <Card id="properties">
        <CardTitle>Check out our properties!</CardTitle>
        <InfoSectionBtnWrap>
          <BuyPropertiesButton onClick={this.changeInfoSelection}>
            Buy
          </BuyPropertiesButton>
          <RentPropertiesButton onClick={this.changeInfoSelection}>
            Rent
          </RentPropertiesButton>
          <SoldPropertiesButton onClick={this.changeInfoSelection}>
            Sold
          </SoldPropertiesButton>
          <OffTheMarketPropertiesButton onClick={this.changeInfoSelection}>
            Off Market
          </OffTheMarketPropertiesButton>
        </InfoSectionBtnWrap>
        <CardContainer>
          {this.state.loading ? (
            <ProcessingImgWrapper
              src={loading_properties}
              alt="Loading Image"
            ></ProcessingImgWrapper>
          ) : (
            <CardWrapper>
              <CardItems>
                {this.state.properties[0] ? (
                  <CardItem>
                    <CardItemLink
                      to={
                        this.state.properties[0].status === "management"
                          ? `/property/${
                              this.state.properties[0].id
                            }/${"forLease"}`
                          : `/property/${
                              this.state.properties[0].id
                            }/${"forSale"}`
                      }
                    >
                      <CardItemImgWrapper>
                        <CardStatus>
                          {this.state.properties[0].status === "listing"
                            ? "FOR SALE"
                            : this.state.properties[0].status === "conditional"
                            ? "UNDER OFFER"
                            : this.state.properties[0].status === "management"
                            ? "FOR LEASE"
                            : this.state.properties[0].status ===
                                "unconditional" || "settled"
                            ? "SOLD"
                            : "OFF THE MARKET"}
                        </CardStatus>
                        <CardItemImg
                          src={
                            this.state.properties[0].photos[0]
                              ? this.state.properties[0].photos[0].url
                              : image_unavailable
                          }
                          alt="Property Image"
                        ></CardItemImg>
                        {this.state.properties[0].status === "conditional" ? (
                          <CardItemUnderOfferImg
                            src={image_under_offer}
                            alt="Under Offer Property Image"
                          ></CardItemUnderOfferImg>
                        ) : (
                          ""
                        )}
                      </CardItemImgWrapper>
                      <CardItemInfo>
                        <CardItemText>
                          {
                            this.state.properties[0].displayAddress.split(
                              ","
                            )[0]
                          }
                        </CardItemText>
                        <CardItemTextGreen>
                          {this.state.properties[0].address.suburb.name}
                        </CardItemTextGreen>
                      </CardItemInfo>
                    </CardItemLink>
                  </CardItem>
                ) : (
                  ""
                )}
                {this.state.properties[1] ? (
                  <CardItem>
                    <CardItemLink
                      to={
                        this.state.properties[1].status === "management"
                          ? `/property/${
                              this.state.properties[1].id
                            }/${"forLease"}`
                          : `/property/${
                              this.state.properties[1].id
                            }/${"forSale"}`
                      }
                    >
                      <CardItemImgWrapper>
                        <CardStatus>
                          {this.state.properties[1].status === "listing"
                            ? "FOR SALE"
                            : this.state.properties[1].status === "conditional"
                            ? "UNDER OFFER"
                            : this.state.properties[1].status === "management"
                            ? "FOR LEASE"
                            : this.state.properties[1].status ===
                                "unconditional" || "settled"
                            ? "SOLD"
                            : "OFF THE MARKET"}
                        </CardStatus>
                        <CardItemImg
                          src={
                            this.state.properties[1].photos[0]
                              ? this.state.properties[1].photos[0].url
                              : image_unavailable
                          }
                          alt="Property Image"
                        ></CardItemImg>
                        {this.state.properties[1].status === "conditional" ? (
                          <CardItemUnderOfferImg
                            src={image_under_offer}
                            alt="Under Offer Property Image"
                          ></CardItemUnderOfferImg>
                        ) : (
                          ""
                        )}
                      </CardItemImgWrapper>
                      <CardItemInfo>
                        <CardItemText>
                          {
                            this.state.properties[1].displayAddress.split(
                              ","
                            )[0]
                          }
                        </CardItemText>
                        <CardItemTextGreen>
                          {this.state.properties[1].address.suburb.name}
                        </CardItemTextGreen>
                      </CardItemInfo>
                    </CardItemLink>
                  </CardItem>
                ) : (
                  ""
                )}
              </CardItems>
              <CardItems>
                {this.state.properties[2] ? (
                  <CardItem>
                    <CardItemLink
                      to={
                        this.state.properties[2].status === "management"
                          ? `/property/${
                              this.state.properties[2].id
                            }/${"forLease"}`
                          : `/property/${
                              this.state.properties[2].id
                            }/${"forSale"}`
                      }
                    >
                      <CardItemImgWrapper>
                        <CardStatus>
                          {this.state.properties[2].status === "listing"
                            ? "FOR SALE"
                            : this.state.properties[2].status === "conditional"
                            ? "UNDER OFFER"
                            : this.state.properties[2].status === "management"
                            ? "FOR LEASE"
                            : this.state.properties[2].status ===
                                "unconditional" || "settled"
                            ? "SOLD"
                            : "OFF THE MARKET"}
                        </CardStatus>
                        <CardItemImg
                          src={
                            this.state.properties[2].photos[0]
                              ? this.state.properties[2].photos[0].url
                              : image_unavailable
                          }
                          alt="Property Image"
                        ></CardItemImg>
                        {this.state.properties[2].status === "conditional" ? (
                          <CardItemUnderOfferImg
                            src={image_under_offer}
                            alt="Under Offer Property Image"
                          ></CardItemUnderOfferImg>
                        ) : (
                          ""
                        )}
                      </CardItemImgWrapper>
                      <CardItemInfo>
                        <CardItemText>
                          {
                            this.state.properties[2].displayAddress.split(
                              ","
                            )[0]
                          }
                        </CardItemText>
                        <CardItemTextGreen>
                          {this.state.properties[2].address.suburb.name}
                        </CardItemTextGreen>
                      </CardItemInfo>
                    </CardItemLink>
                  </CardItem>
                ) : (
                  ""
                )}
                {this.state.properties[3] ? (
                  <CardItem>
                    <CardItemLink
                      to={
                        this.state.properties[3].status === "management"
                          ? `/property/${
                              this.state.properties[3].id
                            }/${"forLease"}`
                          : `/property/${
                              this.state.properties[3].id
                            }/${"forSale"}`
                      }
                    >
                      <CardItemImgWrapper>
                        <CardStatus>
                          {this.state.properties[3].status === "listing"
                            ? "FOR SALE"
                            : this.state.properties[3].status === "conditional"
                            ? "UNDER OFFER"
                            : this.state.properties[3].status === "management"
                            ? "FOR LEASE"
                            : this.state.properties[3].status ===
                                "unconditional" || "settled"
                            ? "SOLD"
                            : "OFF THE MARKET"}
                        </CardStatus>
                        <CardItemImg
                          src={
                            this.state.properties[3].photos[0]
                              ? this.state.properties[3].photos[0].url
                              : image_unavailable
                          }
                          alt="Property Image"
                        ></CardItemImg>
                        {this.state.properties[3].status === "conditional" ? (
                          <CardItemUnderOfferImg
                            src={image_under_offer}
                            alt="Under Offer Property Image"
                          ></CardItemUnderOfferImg>
                        ) : (
                          ""
                        )}
                      </CardItemImgWrapper>
                      <CardItemInfo>
                        <CardItemText>
                          {
                            this.state.properties[3].displayAddress.split(
                              ","
                            )[0]
                          }
                        </CardItemText>
                        <CardItemTextGreen>
                          {this.state.properties[3].address.suburb.name}
                        </CardItemTextGreen>
                      </CardItemInfo>
                    </CardItemLink>
                  </CardItem>
                ) : (
                  ""
                )}
                {this.state.properties[4] ? (
                  <CardItem>
                    <CardItemLink
                      to={
                        this.state.properties[4].status === "management"
                          ? `/property/${
                              this.state.properties[4].id
                            }/${"forLease"}`
                          : `/property/${
                              this.state.properties[4].id
                            }/${"forSale"}`
                      }
                    >
                      <CardItemImgWrapper>
                        <CardStatus>
                          {this.state.properties[4].status === "listing"
                            ? "FOR SALE"
                            : this.state.properties[4].status === "conditional"
                            ? "UNDER OFFER"
                            : this.state.properties[4].status === "management"
                            ? "FOR LEASE"
                            : this.state.properties[4].status ===
                                "unconditional" || "settled"
                            ? "SOLD"
                            : "OFF THE MARKET"}
                        </CardStatus>
                        <CardItemImg
                          src={
                            this.state.properties[4].photos[0]
                              ? this.state.properties[4].photos[0].url
                              : image_unavailable
                          }
                          alt="Property Image"
                        ></CardItemImg>
                        {this.state.properties[4].status === "conditional" ? (
                          <CardItemUnderOfferImg
                            src={image_under_offer}
                            alt="Under Offer Property Image"
                          ></CardItemUnderOfferImg>
                        ) : (
                          ""
                        )}
                      </CardItemImgWrapper>
                      <CardItemInfo>
                        <CardItemText>
                          {
                            this.state.properties[4].displayAddress.split(
                              ","
                            )[0]
                          }
                        </CardItemText>
                        <CardItemTextGreen>
                          {this.state.properties[4].address.suburb.name}
                        </CardItemTextGreen>
                      </CardItemInfo>
                    </CardItemLink>
                  </CardItem>
                ) : (
                  ""
                )}
              </CardItems>
            </CardWrapper>
          )}
          {this.state.loading ? (
            ""
          ) : (
            <CardBtnWrap>
              <CardButton
                // to="/properties"
                to={{
                  pathname: "/properties",
                  data: {
                    buttonclicked: this.state.offTheMarketClick
                      ? "offTheMarketClick"
                      : this.state.soldClick
                      ? "soldClick"
                      : this.state.toRentClick
                      ? "toRentClick"
                      : "toBuyClick",
                  },
                }}
                onMouseEnter={() => this.setState({ hover: true })}
                onMouseLeave={() => this.setState({ hover: false })}
              >
                View All {this.state.hover ? <ArrowForward /> : <ArrowRight />}
              </CardButton>
            </CardBtnWrap>
          )}
        </CardContainer>
      </Card>
    );
  }
}

export default CardSection;
