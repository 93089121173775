export const homeObjOne = {
  id: "team",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Quality professionals who are ready to deliver for you.",
  headline: "Team Setia",
  description:
    "Team Setia is a group of professionals in real estate who are focused and dedicated to get you the best outcome for your property.",
  buttonLabel: "Meet Our Team",
  buttonLink: "/ourteam",
  imgStart: true,
  img: "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/assets/team/team_setia_group_image.jpg",
  alt: "Our Team",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjTwo = {
  id: "aboutus",
  lightBg: false,
  lightText: false,
  lightTextDesc: true,
  topLine: "Our Story",
  headline: "Setia Real Estate",
  description:
    "Setia Real Estate is one of Sydney’s leading and fastest growing real estate agencies.",
  buttonLabel: "Discover More",
  buttonLink: "/aboutus",
  imgStart: false,
  img: "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/assets/static/team_setia_story_image.jpg",
  profilePic: false,
  alt: "Team Image",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjThree = {
  id: "contact",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Ever Wondered What Your Property Is Worth?",
  headline: "Free Appraisal",
  description:
    "Contact Team Setia to our exclusive service that allows you to evaluate your property without getting charged any fees.",
  buttonLabel: "Let's Get Moving",
  buttonLink: "/contactusappraisal",
  imgStart: true,
  img: "https://setia-real-estate-bucket.s3.ap-southeast-2.amazonaws.com/assets/static/free_appraisal_image.jpeg",
  alt: "Free Appraisal",
  dark: false,
  primary: false,
  darkText: true,
};
